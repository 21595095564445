<template>
  <div>
    <ion-list>
      <ion-item v-for="(value, key) in outlets" :key="key">
        <ion-avatar slot="start">
          <img :src="value['receipt_logo']" />
        </ion-avatar>
        <ion-label>
          <h2>
            <router-link
              class="link"
              :to="{ name: 'home', params: { id: value['outlet_id'] } }"
              >{{ value["name"] }}</router-link
            >
          </h2>
          <h5>{{ value["address"] }}</h5>
        </ion-label>
      </ion-item>
    </ion-list>
  </div>
</template>

<script>
export default {
  name: "homePages",
  props: ["outlets"],
};
</script>

<style scoped>
.link {
  text-decoration: none;
  color: #222428;
  font-weight: bold;
}
h5 {
  padding-bottom: 5px;
  color: #999;
  font-size: 0.75rem;
}
</style>
