var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ion-page"},[_c('ion-header',[_c('ion-toolbar',{attrs:{"color":"dark"}},[_c('ion-title',[_vm._v("Product Details")])],1)],1),_c('ion-content',[_c('ion-card',[_c('ion-row',{staticClass:"title"},[_c('ion-col',{attrs:{"size-md":"6","size-xs":"12"}},[_c('img',{attrs:{"src":_vm.productDetail.data.photo,"alt":"detail-product","size-md":"3"}})]),_c('ion-col',{attrs:{"size-md":"6","size-xs":"12"}},[_c('h6',[_vm._v(_vm._s(_vm.productDetail.data.name))]),_c('p',[_vm._v(_vm._s(_vm.productDetail.data.description))]),_c('h6',[_vm._v("Tersedia di Outlet")]),_c('ion-list',_vm._l((_vm.productDetail.data.available),function(product,index){return _c('ion-item',{key:index},[_c('ion-label',[_c('h2',{class:product.stock_status == 'available' ? '' : 'disabled'},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('h3',{class:product.stock_status == 'available' ? '' : 'disabled'},[_vm._v(" "+_vm._s(_vm.formatter.format(product.price_sell))+" ")])]),(product.stock_status == 'available')?_c('ion-button',{attrs:{"slot":"end","color":"danger"},on:{"click":function($event){_vm.addOrder(
                    {
                      product_name: _vm.productDetail.data.name,
                      note: '',
                      price: parseInt(product.price_sell),
                      product_detail_id: parseInt(product.product_detail_id),
                      product_id: _vm.productDetail.data.product_id,
                      photo: _vm.productDetail.data.photo,
                      qty: 1,
                      tax: product.tax,
                    },
                    product
                  )}},slot:"end"},[_vm._v(" Order ")]):_vm._e(),(product.stock_status == 'unavailable')?_c('p',{staticClass:"unavailable",attrs:{"slot":"end"},slot:"end"},[_vm._v(" Run out off stock ")]):_vm._e()],1)}),1)],1)],1)],1)],1),_c('ion-footer',[_c('ion-toolbar',{attrs:{"color":"dark"}},[_c('ion-title',[_vm._v("Powered By "),_c('span',[_vm._v("UNIQ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }