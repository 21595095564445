var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('keep-alive',[_c('transition-group',_vm._l((_vm.categories),function(categori,index){return _c('ion-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],key:index},[(categori.category_id)?_c('ion-grid',[_c('ion-row',[_c('ion-col',{attrs:{"size":"11"},on:{"click":function($event){categori['opened'] = !categori['opened']}}},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('ion-icon',{directives:[{name:"show",rawName:"v-show",value:(categori.has_order),expression:"categori.has_order"}],staticClass:"icon-bookmark",attrs:{"size":"higth","color":"danger","name":"bookmark"}})],1),_c('strong',[_vm._v(_vm._s(categori["category_name"]))])],1),_c('ion-col',{attrs:{"size":"1"}},[(!categori['opened'])?_c('ion-icon',{attrs:{"color":"danger","name":_vm.arrow}}):_vm._e(),(categori['opened'])?_c('ion-icon',{attrs:{"color":"danger","name":_vm.arrow2}}):_vm._e()],1)],1),_c('ion-row',{staticClass:"product-list-wrapper"},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('ion-col',{directives:[{name:"show",rawName:"v-show",value:(categori['opened']),expression:"categori['opened']"}]},_vm._l((categori['products']),function(product,produk){return _c('ion-card',{key:produk,staticClass:"product_card"},[_c('ion-row',[_c('ion-col',{attrs:{"size":"4"}},[_c('ion-img',{staticClass:"productImage",attrs:{"src":product['photo'],"alt":"No Image"},on:{"click":function($event){return _vm.detailsProduct(
                            product['product_id'],
                            product['product_detail_id'],
                            product['name'],
                            product['price_sell'],
                            product['description'],
                            product['photo'],
                            product['stock_status'],
                            product['tax']
                          )}}})],1),_c('ion-col',{attrs:{"size":"8"}},[_c('h6',{on:{"click":function($event){return _vm.detailsProduct(
                            product['product_id'],
                            product['product_detail_id'],
                            product['name'],
                            product['price_sell'],
                            product['description'],
                            product['photo'],
                            product['stock_status'],
                            product['tax']
                          )}}},[_vm._v(" "+_vm._s(product["name"])+" ")]),(product['description'] != '')?_c('p',{staticClass:"desc"},[_vm._v(" "+_vm._s(product["description"])+" ")]):_vm._e(),(product['stock_status'] == 'available')?_c('strong',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.formatter.format(product["price_sell"]))+" ")]):_vm._e(),(product['stock_status'] == 'unavailable')?_c('strong',{staticClass:"out"},[_vm._v("OUT OF STOCK")]):_vm._e()]),_c('ion-col',[_c('transition',{attrs:{"name":"bounce"}},[(!_vm.result(product['product_detail_id']))?_c('ion-button',{staticClass:"ion-float-right add-btn",attrs:{"disabled":product['stock_status'] == 'unavailable',"color":"danger","clear":"","size":"small"},on:{"click":function($event){return _vm.add(
                              product['name'],
                              product['price_sell'],
                              product['product_id'],
                              product['product_detail_id'],
                              product['photo'],
                              '',
                              product['tax']
                            )}}},[_vm._v(" ADD + ")]):_vm._e()],1),_c('transition',{attrs:{"name":"slide-fade"}},[(
                            product['stock_status'] == 'available' &&
                              _vm.result(product['product_detail_id']) >= 1
                          )?_c('ion-row',{staticClass:"trans ion-float-right"},[(product['stock_status'] == 'available')?_c('ion-button',{staticClass:"ion-float-right order-button",attrs:{"color":"danger","size":"small"},on:{"click":function($event){return _vm.minus(product['name'])}}},[_vm._v(" - ")]):_vm._e(),_c('div',{staticClass:"qty-result"},[_vm._v(" "+_vm._s(_vm.result(product["product_detail_id"]))+" ")]),_c('ion-button',{staticClass:"ion-float-right order-button",attrs:{"color":"danger","size":"small"},on:{"click":function($event){return _vm.sum(product['name'])}}},[_vm._v(" + ")])],1):_vm._e()],1)],1)],1)],1)}),1)],1)],1)],1):_vm._e(),(categori.product_id)?_c('ion-card',{staticClass:"product_card  card_result"},[_c('ion-row',[_c('ion-col',{attrs:{"size":"4"}},[_c('ion-img',{staticClass:"productImage",attrs:{"src":categori['photo'],"alt":"No Image"},on:{"click":function($event){return _vm.detailsProduct(
                    categori['product_id'],
                    categori['product_detail_id'],
                    categori['name'],
                    categori['price_sell'],
                    categori['description'],
                    categori['photo'],
                    categori['stock_status'],
                    categori['tax']
                  )}}})],1),_c('ion-col',{attrs:{"size":"8"}},[_c('h6',{on:{"click":function($event){return _vm.detailsProduct(
                    categori['product_id'],
                    categori['product_detail_id'],
                    categori['name'],
                    categori['price_sell'],
                    categori['description'],
                    categori['photo'],
                    categori['stock_status'],
                    categori['tax']
                  )}}},[_vm._v(" "+_vm._s(categori["name"])+" ")]),(categori['description'] != '')?_c('p',{staticClass:"desc"},[_vm._v(" "+_vm._s(categori["description"])+" ")]):_vm._e(),(categori['stock_status'] == 'available')?_c('strong',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.formatter.format(categori["price_sell"]))+" ")]):_vm._e(),(categori['stock_status'] == 'unavailable')?_c('strong',{staticClass:"out"},[_vm._v("OUT OF STOCK")]):_vm._e()]),_c('ion-col',[_c('transition',{attrs:{"name":"bounce"}},[(!_vm.result(categori['product_detail_id']))?_c('ion-button',{staticClass:"ion-float-right add-btn",attrs:{"disabled":categori['stock_status'] == 'unavailable',"color":"danger","clear":"","size":"small"},on:{"click":function($event){return _vm.add(
                      categori['name'],
                      categori['price_sell'],
                      categori['product_id'],
                      categori['product_detail_id'],
                      categori['photo'],
                      '',
                      categori['tax']
                    )}}},[_vm._v(" ADD + ")]):_vm._e()],1),_c('transition',{attrs:{"name":"slide-fade"}},[(
                    categori['stock_status'] == 'available' &&
                      _vm.result(categori['product_detail_id']) >= 1
                  )?_c('ion-row',{staticClass:"trans ion-float-right"},[(categori['stock_status'] == 'available')?_c('ion-button',{staticClass:"ion-float-right order-button",attrs:{"color":"danger","size":"small"},on:{"click":function($event){return _vm.minus(categori['name'])}}},[_vm._v(" - ")]):_vm._e(),_c('div',{staticClass:"qty-result"},[_vm._v(" "+_vm._s(_vm.result(categori["product_detail_id"]))+" ")]),_c('ion-button',{staticClass:"ion-float-right order-button",attrs:{"color":"danger","size":"small"},on:{"click":function($event){return _vm.sum(categori['name'])}}},[_vm._v(" + ")])],1):_vm._e()],1)],1)],1)],1):_vm._e()],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }