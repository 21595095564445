import Vue from 'vue'
import { IonicVueRouter } from '@ionic/vue'
import Home from '@/views/Home.vue'
import HomePage from '@/views/HomePage.vue'
import DetailProduct from '@/views/DetailProduct.vue'

Vue.use(IonicVueRouter)

const routes = [
  {
    path: '/',
    name: 'homePage',
    component: HomePage
  },
  {
    path: '/:id',
    name: 'home',
    component: Home
  },
  {
    path: '/:id/:detail_id',
    name: 'detailProduct',
    component: DetailProduct,
  },
  {
    path: '/',
    redirect: '/29'
  }
]

const router = new IonicVueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
