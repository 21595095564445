<template>
  <div class="ion-page">
    <ion-header>
      <ion-toolbar color="dark">
        <ion-title>Product Details</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card>
        <ion-row class="title">
          <ion-col size-md="6" size-xs="12">
            <img
              :src="productDetail.data.photo"
              alt="detail-product"
              size-md="3"
            />
          </ion-col>
          <ion-col size-md="6" size-xs="12">
            <h6>{{ productDetail.data.name }}</h6>
            <p>{{ productDetail.data.description }}</p>
            <h6>Tersedia di Outlet</h6>
            <ion-list>
              <ion-item
                v-for="(product, index) in productDetail.data.available"
                :key="index"
              >
                <ion-label>
                  <h2
                    :class="
                      product.stock_status == 'available' ? '' : 'disabled'
                    "
                  >
                    {{ product.name }}
                  </h2>
                  <h3
                    :class="
                      product.stock_status == 'available' ? '' : 'disabled'
                    "
                  >
                    {{ formatter.format(product.price_sell) }}
                  </h3>
                </ion-label>
                <ion-button
                  slot="end"
                  v-if="product.stock_status == 'available'"
                  color="danger"
                  @click="
                    addOrder(
                      {
                        product_name: productDetail.data.name,
                        note: '',
                        price: parseInt(product.price_sell),
                        product_detail_id: parseInt(product.product_detail_id),
                        product_id: productDetail.data.product_id,
                        photo: productDetail.data.photo,
                        qty: 1,
                        tax: product.tax,
                      },
                      product
                    )
                  "
                >
                  <!-- <router-link
                    class="link"
                    :to="{ name: 'home', params: { id: outletId } }"
                  >
                  </router-link> -->
                  Order
                </ion-button>
                <p
                  class="unavailable"
                  slot="end"
                  v-if="product.stock_status == 'unavailable'"
                >
                  Run out off stock
                </p>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-content>
    <ion-footer>
      <ion-toolbar color="dark">
        <ion-title>Powered By <span>UNIQ</span></ion-title>
      </ion-toolbar>
    </ion-footer>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: "detailProduct",
  data() {
    return {
      formatter: new Intl.NumberFormat("ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 2,
      }),
      productDetail: null,
    };
  },
  async created() {
    let getProduct = async () => {
      let detailId = parseInt(this.$route.params.detail_id);
      let url = `${process.env.VUE_APP_BASE_URL}product/availability/detail/${detailId}?include=tax`;
      let myHeaders = new Headers();
      myHeaders.append("Public-Key", `${process.env.VUE_APP_PUBLIC_KEY}`);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const req = await fetch(url, requestOptions);
      this.productDetail = await req.json();
      console.log(this.productDetail);
    };
    getProduct();
  },
  methods: {
    addOrder(data, product) {
      let lastOrder = localStorage.getItem("last-order");

      data.tax.map((d) => {
        if (d.tax_status == "permanent") {
          return { ...data, tax: d };
        }
      });

      console.log(data);

      let arrOrder;
      if (lastOrder != null) {
        let arrOrder = JSON.parse(lastOrder);

        let check = arrOrder.find(
          (item) => item.product_id === data.product_id
        );

        if (check === undefined) {
          arrOrder.push(data);
          localStorage.setItem("last-order", JSON.stringify(arrOrder));
        } else {
          // let newArrOrder = arrOrder.map((item) => {
          //   if (item.product_id === data.product_id) {
          //     item.qty++;
          //     return { ...item };
          //   }
          //   return { ...item };
          // });

          arrOrder = [];
          arrOrder.push(data);
          localStorage.setItem("last-order", JSON.stringify(arrOrder));
        }
      } else {
        arrOrder = [];
        arrOrder.push(data);
        localStorage.setItem("last-order", JSON.stringify(arrOrder));
      }

      // console.log(product);

      window.location.replace("/" + parseInt(product.outlet_id));
    },
  },
};
</script>

<style scoped>
span {
  color: #f05e23;
}

ion-header {
  text-align: center;
}

ion-footer {
  text-align: center;
}

img {
  margin-top: 14px;
  margin-bottom: 14px;
  border-radius: 7px;
}

/* media query */
@media screen and (max-width: 600px) {
  img {
    height: 200px;
    object-fit: cover;
  }
}

ion-col {
  padding: 0 14px;
}

ion-col h6 {
  color: #333;
  font-weight: bold;
}

.unavailable {
  color: crimson;
  font-weight: bold;
}

.disabled {
  color: #737373;
}

ion-label h2 {
  color: #333;
}

ion-label h3 {
  color: #333;
}

.link {
  text-decoration: none;
  color: #222428;
  font-weight: bold;
}
</style>
