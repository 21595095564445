<template>
  <div class="backdrop" @click="hideBackdrop"></div>
</template>

<script>
export default {
  name: "backdrop",
  setup() {},
  methods: {
    hideBackdrop() {
      this.$emit("hide-backdrop");
    },
  },
};
</script>

<style scoped>
.backdrop {
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.3;
  position: fixed;
  z-index: 1;
}
</style>
