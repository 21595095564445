<template>
  <transition name="slide-fade">
    <ion-card class="ion-text-center cart">
      <ion-row class="header-modal">
        <h5>Detail orders</h5>
      </ion-row>
      <ion-infinite-scroll>
        <ion-list v-for="(order, orders) in orderList" :key="orders">
          <ion-item v-if="order['qty'] > 0">
            <ion-avatar slot="start">
              <ion-img :src="order['photo']"></ion-img>
            </ion-avatar>
            <ion-label>
              <h5>{{ order["qty"] }} x {{ order["product_name"] }}</h5>
              <h5>
                {{ formatter.format(order["qty"] * order["price"]) }}
              </h5>
            </ion-label>
            <ion-row>
              <ion-button
                @click="reduced(order['product_detail_id'])"
                color="danger"
                class="order-button"
              >
                -
              </ion-button>
              <div class="qty-result">
                <span>
                  {{ result(order["product_detail_id"]) }}
                </span>
              </div>
              <ion-button
                @click="add(order['product_detail_id'])"
                color="danger"
                class="order-button"
              >
                +
              </ion-button>
            </ion-row>
          </ion-item>
        </ion-list>
      </ion-infinite-scroll>
      <p class="tax-list" v-for="(tax, key, i) in taxs()" :key="i">
        *{{ key }}
        {{ formatter.format(taxPerItem(tax)) }}
      </p>
      <ion-row class="footer-modal">
        <ion-col class="grand-total">
          <p class="total-title">Grand total:</p>
          <p class="total">{{ formatter.format(sum(orderList)) }}</p>
        </ion-col>
        <ion-button
          class="order-btn"
          v-on:click="deals(orderList), presentLoading()"
        >
          Order
        </ion-button>
      </ion-row>
    </ion-card>
  </transition>
</template>

<script>
export default {
  name: "outletCheckOrder",
  props: ["orderList", "editID"],
  data() {
    return {
      formatter: new Intl.NumberFormat("ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 2,
      }),
      hiden: false,
      order_code: "",
      qrcode: "",
      timeout: 5000,
      groupTax: {},
    };
  },
  methods: {
    taxs() {
      let allTax = [];
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i].tax.length != 0) {
          for (let j = 0; j < this.orderList[i].tax.length; j++) {
            if (this.orderList[i].tax[j].jumlah > 100) {
              allTax.push({
                order_qty: this.orderList[i].qty,
                price: this.orderList[i].price,
                tax_name: this.orderList[i].tax[j].name,
                tax_amount: this.orderList[i].tax[j].jumlah,
              });
            } else {
              allTax.push({
                order_qty: this.orderList[i].qty,
                price: this.orderList[i].price,
                tax_name: this.orderList[i].tax[j].name,
                tax_amount: this.orderList[i].tax[j].jumlah,
              });
            }
          }
        }
      }

      let groupTax = allTax.reduce((k, v) => {
        k[v.tax_name] = [...(k[v.tax_name] || []), v];
        return k;
      }, {});
      return groupTax;
    },
    hide(hiden) {
      this.$emit("hider", hiden);
    },
    sum(o) {
      let t = [];
      let sum = 0;
      for (let i = 0; i < o.length; i++) {
        if (o[i].tax.length != 0) {
          for (let j = 0; j < o[i].tax.length; j++) {
            if (o[i].tax.length > 1) {
              if (o[i].tax[j].jumlah > 100) {
                t.push(o[i].tax[j].jumlah);
              } else {
                t.push(
                  o[i].qty *
                    this.priceIncludeTax(o[i].price, o[i].tax[j].jumlah)
                );
              }
            } else {
              if (o[i].tax[j].jumlah > 100) {
                t.push(o[i].price * o[i].qty + o[i].tax[j].jumlah);
              } else {
                t.push(
                  o[i].qty *
                    this.priceIncludeTax(o[i].price, o[i].tax[j].jumlah)
                );
              }
            }
          }
        } else {
          t.push(o[i].qty * o[i].price);
        }
      }
      for (let a = 0; a < t.length; a++) {
        sum += t[a];
      }
      // prmbulatan
      return Math.ceil(sum / 100) * 100 ;
    },
    add(prodDetailId) {
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i]["product_detail_id"] == prodDetailId) {
          if (this.orderList[i]["qty"]) {
            this.orderList[i]["qty"] += 1;
          }
        }
      }
      this.$emit("changeListOrder", this.orderList);
    },
    reduced(prodDetailId) {
      for (let i = 0; i < this.orderList.length; i++) {
        if (this.orderList[i]["product_detail_id"] == prodDetailId) {
          if (this.orderList[i]["qty"]) {
            this.orderList[i]["qty"] -= 1;
            if (this.orderList[i]["qty"] == 0) {
              this.orderList.splice(i, 1);
            }
          }
        }
      }
      this.$emit("changeListOrder", this.orderList);
    },
    result(prodDetailId) {
      if (this.orderList.length) {
        // console.log(this.product);
        for (let i = 0; i < this.orderList.length; i++) {
          if (this.orderList[i].product_detail_id == prodDetailId) {
            return this.orderList[i].qty;
          }
        }
      }
    },
    taxPerItem(tax) {
      let t = 0;
      let tx = 0;
      let txPrice = 0;
      for (let i = 0; i < tax.length; i++) {
        if (tax[i].tax_amount > 100) {
          t = tax[i].tax_amount;
        } else {
          tx = tax[i].tax_amount * tax[i].order_qty;
          txPrice = (tax[i].price * tx) / 100;
          t += txPrice;
        }
      }
      return t;
      // let t = 0;
      // if (tax.tax_amount > 100) {
      //   t = tax.tax_amount;
      //   return t;
      // } else {
      //   let tx = tax.tax_amount * tax.order_qty;
      //   t = (price * tx) / 100;
      //   return t;
      // }
    },
    priceIncludeTax(price, tax) {
      let total = 0;
      let t = (price * tax) / 100;
      total = price + t;
      return total;
    },
    async deals(order) {
      let myHeaders = new Headers();
      myHeaders.append("Public-Key", `${process.env.VUE_APP_PUBLIC_KEY}=`);
      let id = parseInt(this.$route.params.id);
      let raw;
      let url = "";
      let method = "";
      // console.log(this.editID);
      if (this.editID !== "0" && this.editID !== "") {
        raw = JSON.stringify({ order_list: order });
        url = `transaction/self_order/${this.editID}`;
        method = "PUT";
      } else {
        raw = JSON.stringify({ outlet_id: id, order_list: order });
        url = "transaction/self_order";
        method = "POST";
      }

      let requestOptions = {
        method: method,
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      let res = await fetch(
        `${process.env.VUE_APP_BASE_URL}${url}`,
        requestOptions
      );
      if (res.status === 200) {
        let data = await res.json();
        // console.log(await res.json())
        let order_code = data["data"]["order_code"],
          qrcode = data["data"]["qrcode"],
          expired = data["data"]["expired"];

        let date = new Date(expired);
        let hours = date.getHours(),
          minutes = date.getMinutes();

        this.$emit("orderData", order_code, qrcode, hours, minutes);
        localStorage.setItem(
          "history-order",
          JSON.stringify({
            outlet_id: id,
            items: order,
            trans: {
              order_code,
              qrcode,
              expired,
            },
          })
        );
        localStorage.removeItem("last-order");
      }
    },
    presentLoading() {
      return this.$ionic.loadingController
        .create({
          message: "Loading",
          duration: this.timeout,
        })
        .then((l) => {
          setTimeout(function() {
            l.dismiss();
          }, this.timeout);
          return l.present();
        });
    },
  },
};
</script>

<style scoped>
ion-card {
  position: fixed;
  /* top: 0; */
  bottom: 50%;
  right: 0;
  left: 0;
  width: 50%;
  transform: translate(50%, 50%);
  background: #fafafa;
  transition: all 0.5s ease-in-out;
  padding-bottom: 1rem;
  /* border-top-left-radius: 20px; */
  /* border-top-right-radius: 20px; */
  /* min-height: 80%; */
  margin: 0;
  z-index: 80;
}
ion-label h5 {
  font-size: 0.75rem;
  font-weight: 700;
}
.total-title {
  font-size: 0.8rem;
}
.total {
  font-size: 1rem;
  color: black;
  font-weight: bold;
}
.cart {
  overflow-y: scroll;
  max-height: 85vh;
  /* height: 30vh; */
}
ion-row ion-button {
  margin: 0;
  padding: 0;
  --box-shadow: none;
}
ion-row {
  padding-left: 0;
  text-align: end;
}
ion-row .order-button {
  --border-radius: 50%;
  height: 30px;
  width: 30px;
}
.qty-result {
  width: 25px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #8C1115;
  margin: 0 0.5rem 0 0.5rem;
  font-weight: bold;
}
.tax-div i {
  font-size: 0.5rem;
  color: grey;
}
.tax-list {
  text-align: right;
  margin: 0 1rem 0 0;
  font-size: 0.7rem;
}
.header-modal {
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
.footer-modal {
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  border-top: 1px solid grey;
  margin-top: 0.4rem;
}
ion-row .grand-total {
  text-align: left;
  margin: 0;
}
ion-row .grand-total p {
  margin: 0;
}
.order-btn {
  --background: #8C1115;
}

@media screen and (max-width: 600px) {
  ion-card {
    position: fixed;
    /* top: 0; */
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    transform: none;
    background: #fafafa;
    transition: all 0.5s ease-in-out;
    padding-bottom: 1rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    /* min-height: 80%; */
    margin: 0;
    z-index: 80;
  }
}

/* transition */
.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100px);
  opacity: 0;
}
</style>
