import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Ionic from '@ionic/vue'
import '@ionic/core/css/core.css'
import '@ionic/core/css/ionic.bundle.css'
import dotenv from 'dotenv'
import './registerServiceWorker'
import VueAnalytics from 'vue-analytics';

dotenv.config()

Vue.use(Ionic)
Vue.config.productionTip = false

export const EventBus = new Vue();

Vue.use(VueAnalytics, {
  id: 'G-64LD8N1876',
  router,
  autoTracking: {
    screenview: true
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
