<template>
  <div class="ion-text-center">
    <ion-row>
      <ion-col size-md="6" size-xs="12">
        <h5 class="ion-padding">
          Tunjukan QR Code ini ke kasir sebelum jam {{ orderCode[2] }}:<span
            v-if="orderCode[3] < 10"
            >0</span
          >{{ orderCode[3] }}
        </h5>
        <ion-card color="light" class="ion-text-center ion-card-self-order">
          <div class="qr-wrapper">
            <ion-img
              class="productImage"
              :src="orderCode[1]"
              alt="No Image"
            ></ion-img>
          </div>
        </ion-card>
        <div>
          <p>Order Code</p>
          <h3>{{ orderCode[0] }}</h3>
        </div>
        <ion-card class="order-warning">
          <div class="warning-icon">
            <ion-icon size="large" color="warning" name="alert"></ion-icon>
          </div>
          <div class="warning-text">
            <p>
              - Pesanan belum akan diproses sebelum anda menunjukkan QR-Code ini
              ke kasir
            </p>
            <p>- Pembayaran hanya dapat di lakukan di kasir</p>
          </div>
        </ion-card>
      </ion-col>
      <ion-col size-md="6" size-xs="12">
        <ion-card class="input-card">
          <div class="input_wrapper">
            <h4>Personal information</h4>
            <p>Tambahkan data diri anda untuk mempermudah proses transaksi</p>
            <input
              type="text"
              :value="customer"
              @change="customer = $event.target.value"
              class="input_name"
              placeholder="Nama"
            />
          </div>
          <div>
            <ion-button @click="saveName()" expand="block" class="save-btn">
              save
            </ion-button>
          </div>
        </ion-card>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
export default {
  name: "outletOrder",
  props: ["orderCode", "qrCode", "hours", "minutes"],
  data() {
    return {
      customer: "",
      disabled: false,
    };
  },
  beforeMount() {
    let cookieName = this.getCookie("customer");
    if (cookieName != "") {
      this.customer = cookieName;
    }
    if (this.customer != "") {
      // console.log(this.customer);
      this.saveName();
    }
    if (this.customer != "") {
      this.disabled = !this.disabled;
    }
    // console.log(process.env.VUE_APP_LOCAL);
    // console.log(this.getCookie("customer"));
  },
  methods: {
    async openToast() {
      const toast = await this.$ionic.toastController.create({
        message: "Nama anda telah di simpan",
        duration: 5000,
      });
      return toast.present();
    },
    async saveName() {
      document.cookie = `customer=${this.customer}`;
      let myHeaders = new Headers();
      myHeaders.append("Public-Key", `${process.env.VUE_APP_PUBLIC_KEY}`);

      let bodyUrlEncoded = new URLSearchParams();
      bodyUrlEncoded.append("customer_name", this.customer);

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: bodyUrlEncoded,
        redirect: "follow",
      };
      // let url = `${process.env.VUE_APP_BASE_URL}self-order/${this.orderCode}`;

      const req = await fetch(
        `${process.env.VUE_APP_BASE_URL}self-order/${this.orderCode[0]}`,
        requestOptions
      );
      const res = await req.json();
      if (res.status == true) {
        this.openToast();
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
};
</script>

<style scoped>
.ion-card-self-order {
  background-color: white;
  box-shadow: none;
  border: 2px solid #f5f5f5;
  border-radius: 10px;
}

.qr-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 3rem; */
}
h3 {
  margin-top: 0.5rem;
}
h5 {
  background: rgba(251, 138, 0, 0.7);
  margin: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}
ion-chip {
  margin: 1rem 0 0 0;
}
.order-warning {
  margin: 0 0.5rem 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: none;
  background: #f5f5f5;
}

.warning-text {
  text-align: left;
  font-size: 0.8rem;
  width: 80%;
}
.order-warning .warning-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}
.input-card {
  margin: 1rem 0.5rem 1rem 0.5rem;
  box-shadow: none;
  background: #f5f5f5;
}
.input_wrapper {
  margin: 0.5rem;
  text-align: left;
}
.input-card h4 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}
.input-card p {
  margin: 0.2rem 0 0.5rem 0;
  font-size: 0.75rem;
  color: #737373;
}
.input_name {
  /* width: calc(100% - 1.6rem); */
  width: 100%;
  line-height: 1rem;
  height: 2.5rem;
  box-sizing: border-box;
  background-color: #d6d4d4;
  border: none;
  padding: 0 0.8rem;
}
input[type="text"] {
  border-radius: calc(2.5rem / 5);
}
.input_name:focus {
  outline: none;
}
.save-btn {
  margin: 0 0.5rem 1rem 0.5rem;
  --box-shadow: none;
  --background: #222428;
}
</style>
