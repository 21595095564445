<template>
  <div>
    <div>
      <ion-skeleton-text class="header" animated></ion-skeleton-text>
    </div>
    <div v-for="(a, b) in boilerPlate" :key="a">
      <ion-skeleton-text class="text-skeleton" animated>{{
        b
      }}</ion-skeleton-text>
    </div>
  </div>
</template>

<script>
export default {
  name: "skeleton",
  data() {
    return {
      boilerPlate: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
  },
};
</script>

<style scoped>
.header {
  margin: 0.6rem;
  width: calc(100% - 1.2rem);
  height: 120px;
  border-radius: 5px;
}
.text-skeleton {
  margin: 0.6rem;
  height: 40px;
  width: calc(100% - 1.2rem);
  border-radius: 5px;
}
</style>
