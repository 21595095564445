<template>
  <ion-app>
    <div class="ion-page">
      <!-- Header -->
      <ion-header>
        <ion-toolbar color="dark" mode="ios">
          <ion-buttons slot="start" mode="ios">
            <ion-back-button></ion-back-button>
          </ion-buttons>
          <ion-title>Self Order</ion-title>
        </ion-toolbar>
        <ion-toolbar color="dark" v-if="orderCode == ''">
          <ion-searchbar
            mode="ios"
            :value="productName"
            @input="productName = $event.target.value"
            @ionChange="searchProducts"
            @ionClear="productName = ''"
          ></ion-searchbar>
        </ion-toolbar>
      </ion-header>
      <!-- Content -->

      <ion-content>
        <backdrop
          v-show="backdropOrder == true && orderCode == ''"
          @click="hideBackdropOrder"
          v-on:hide-backdrop="hideBackdropOrder"
        />
        <backdrop
          v-show="showProductDetails"
          @click="hideBackdrop"
          v-on:hide-backdrop="hideBackdrop"
        />
        <!-- outlet header card -->
        <ion-row>
          <ion-col size-md="6" size-xs="12">
            <outletHome
              v-show="show"
              v-if="orderCode == ''"
              v-bind:outlets="outlets"
            />
            <skeleton v-show="!show" />
          </ion-col>
          <!-- history last order -->
          <ion-col size-md="6" size-xs="12">
            <skeleton v-show="!show" />
            <transition name="bounce">
              <ion-card
                v-show="showHistoryLastOrder"
                class="history-order"
                v-if="orderCode == ''"
              >
                <div>
                  <h5>Transaksi terakhir anda:</h5>
                  <p>
                    {{ prodNameInHisOrder() }}
                  </p>
                  <p>Expired at {{ expiredHistoryOrder(historyOrder) }}</p>
                </div>
                <ion-button class="history-button" @click="deals(historyOrder)">
                  Tampikan kode
                </ion-button>
              </ion-card>
            </transition>
            <ion-infinite-scroll>
              <!-- Outlet category -->
              <outletCategory
                v-if="orderCode == ''"
                v-on:get-items="getItems"
                v-on:details-product="detailsProduct"
                v-bind:categories="filteredCategories"
                v-bind:productFormLocalStorage="product"
                v-bind:product="product"
                v-bind:show="show"
              />
            </ion-infinite-scroll>
          </ion-col>
        </ion-row>
        <ion-card class="footers" v-if="orderCode == ''" color="dark">
          <p class="ion-text-center footer">Powered By <span>UNIQ</span></p>
        </ion-card>
        <div v-if="orderCode == ''" class="ion-padding">
          <div class="ion-padding"></div>
        </div>
        <!-- Outlet check order -->
        <outletCheckOrder
          v-on:orderData="OrderData"
          @changeListOrder="changeListOrder"
          v-show="order"
          v-if="orderCode == '' && qty()"
          v-bind:orderList="product"
          v-bind:editID="editID"
        />
        <transition name="slide-fade">
          <ion-button
            @click="orderList"
            class="trans"
            id="outlet"
            v-if="qty() && order == false && product.length != 0"
            color="danger"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              viewBox="0 0 512 512"
            >
              <title>Bag Handle</title>
              <path
                d="M80 176a16 16 0 00-16 16v216c0 30.24 25.76 56 56 56h272c30.24 0 56-24.51 56-54.75V192a16 16 0 00-16-16zM160 176v-32a96 96 0 0196-96h0a96 96 0 0196 96v32"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
              />
              <path
                d="M160 224v16a96 96 0 0096 96h0a96 96 0 0096-96v-16"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
              />
            </svg>

            <p class="qty-float">
              {{ qty() }}
            </p>
          </ion-button>
        </transition>
        <!-- input url -->
        <inputUrlCopy v-show="showLinkInput" v-bind:url="url"></inputUrlCopy>
        <!-- product details -->
        <productDetails
          v-show="showProductDetails"
          v-bind:productDetails="productDetails"
          v-bind:checkProductAlready="checkProductAlready()"
          v-on:generate-url="generateUrl"
          v-on:add-product-from-details="addProductFromDetails"
        />
        <!-- Outlet order -->
        <div v-if="orderCode != ''">
          <outletOrder v-bind:orderCode="[orderCode, qrCode, hours, minutes]" />
          <ion-card class="ion-text-center float-submit-order">
            <form @submit="sendWa" v-show="false">
              <ion-list>
                <ion-item class="default-margin">
                  <ion-label position="floating"
                    >Masukkan nomor WhatsApp anda</ion-label
                  >
                  <ion-input
                    :value="wa"
                    @input="wa = $event.target.value"
                  ></ion-input>
                </ion-item>
              </ion-list>
              <ion-button
                class="default-margin"
                type="submit"
                expand="block"
                color="danger"
                :disabled="disableSendWa"
              >
                Kirim ke WhatsApp
              </ion-button>
            </form>
          </ion-card>
        </div>
      </ion-content>
    </div>
  </ion-app>
</template>

<script>
// @ is an alias to /src
import outletCheckOrder from "@/components/outletCheckOrder";
import outletHome from "@/components/outletHome";
import outletCategory from "@/components/outletCategory";
import outletOrder from "@/components/outletOrder";
import productDetails from "@/components/productDetails";
import inputUrlCopy from "@/components/inputUrlCopy";
import backdrop from "@/components/backdrop/backdrop";
import skeleton from "@/components/skeleton/skeleton";

export default {
  name: "home",
  components: {
    outletHome,
    outletCategory,
    outletCheckOrder,
    outletOrder,
    productDetails,
    inputUrlCopy,
    backdrop,
    skeleton,
  },
  props: {
    timeout: { type: Number, default: 5000 },
  },
  data() {
    return {
      categories: [],
      filteredCategories: [],
      outlets: [],
      product: [],
      order: false,
      orderCode: "",
      qrCode: "",
      hours: "",
      minutes: "",
      wa: localStorage.getItem("wa_number") || "",
      productName: "",
      editID: "",
      disableSendWa: false,
      historyOrder: JSON.parse(localStorage.getItem("history-order")) ?? [],
      showProductDetails: false,
      productDetails,
      url: "",
      showLinkInput: false,
      backdropOrder: false,
      show: false,
      showHistoryLastOrder: false,
    };
  },
  //* Fetch Api *//
  async created() {
    this.editID = this.$route.query.edit || "0";
    let id = this.$route.params.id;
    await this.getOutlet(id);
    await this.getProductCategory(id);

    const getProductsInCard = async () => {
      let myHeaders = new Headers();
      myHeaders.append("Public-Key", `${process.env.VUE_APP_PUBLIC_KEY}`);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const res = await fetch(
        `${process.env.VUE_APP_BASE_URL}transaction/self_order/${id}/${this.editID}`,
        requestOptions
      );
      const data = await res.json();
      if (data.status == 200) {
        const products = [].concat(
          ...this.categories.map((item) => item.products)
        );
        this.product = data.data.order_list.map((item) => ({
          note: item.note,
          price: item.price,
          product_detail_id: item.product_detail_id,
          product_name: products.find((p) => {
            if (p.product_detail_id == item.product_detail_id) {
              return p;
            }
            return;
          }).name,
          qty: item.qty,
        }));
      }
    };
    getProductsInCard();
  },
  beforeMount() {
    if (
      localStorage.getItem("last-order") != undefined &&
      JSON.parse(localStorage.getItem("last-order")).length != 0
    ) {
      let lastOrders = localStorage.getItem("last-order") ?? [];
      this.product = JSON.parse(lastOrders);
    }
    setTimeout(() => {
      this.show = true;
    }, 2000);

    //* show history last order
    let outlet_id = this.$route.params.id;
    if (
      this.historyOrder.items != undefined &&
      this.historyOrder.items.length > 0
    ) {
      if (this.historyOrder.outlet_id == outlet_id) {
        let expired = this.historyOrder.trans.expired;
        let d = new Date(expired);
        let now = new Date();

        if (now.getTime() >= d.getTime()) {
          localStorage.removeItem("history-order");
        } else {
          this.showHistoryLastOrder = true;
        }
      }
    }
  },
  methods: {
    //* checkout history last order
    deals(historyOrder) {
      this.showHistoryLastOrder = false;
      this.orderCode = historyOrder.trans.order_code;
      this.qrCode = historyOrder.trans.qrcode;
      let expired = historyOrder.trans.expired;
      let date = new Date(expired);
      this.hours = date.getHours();
      this.minutes = date.getMinutes();

      //* remove history last order from localStorage
      // localStorage.removeItem("history-order");
    },
    expiredHistoryOrder(historyOrder) {
      if (historyOrder != "") {
        let expired = historyOrder.trans.expired;
        let date = new Date(expired);
        let hours = date.getHours();
        let minute = date.getMinutes();

        return `${hours.toString()}:${minute.toString()}`;
      }
    },
    changeListOrder(newOrder) {
      this.product = newOrder;
      localStorage.setItem("last-order", JSON.stringify(newOrder));
      if (this.product.length == 0) {
        this.backdropOrder = !this.backdropOrder;
        this.order = false;
      }
    },
    prodNameInHisOrder() {
      if (this.historyOrder.items != undefined) {
        let prodName = this.historyOrder.items
          .map((item) => item.product_name)
          .toString()
          .slice(0, 35);
        return prodName + "...";
      }
    },
    //* Get product category by outlet id
    async getProductCategory(id) {
      let myHeaders = new Headers();
      myHeaders.append("Public-Key", `${process.env.VUE_APP_PUBLIC_KEY}`);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const res = await fetch(
        `${process.env.VUE_APP_BASE_URL}product/outlet/${id}/group/subcategory?include=tax`,
        requestOptions
      );
      const data = await res.json();
      this.categories = data["data"].map((item) => ({
        opened: false,
        has_order: false,
        ...item,
      }));
      this.filteredCategories = this.categories;
    },
    //* Get outlet by id
    async getOutlet(id) {
      let myHeaders = new Headers();
      myHeaders.append("Public-Key", `${process.env.VUE_APP_PUBLIC_KEY}`);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const res = await fetch(
        `${process.env.VUE_APP_BASE_URL}outlet/detail/${id}`,
        requestOptions
      );
      let outletData = await res.json();
      this.outlets = outletData["data"];
    },
    //* Get items from PandaCategory Component
    getItems(items) {
      this.product = items;
    },
    //* Sum Order
    qty() {
      if (this.product.length > 0) {
        let qty_sum = 0;

        this.product.forEach(function(obj) {
          qty_sum += obj["qty"];
        });
        return qty_sum;
      }
    },
    //* search producst
    searchProducts() {
      if (this.productName) {
        const products = [].concat(
          ...this.categories.map((item) => item.products)
        );
        this.filteredCategories = products.filter((product) => {
          const name = product.name.toLowerCase();
          const description = product.description ?? "";
          // console.log(description);
          if (
            name.includes(this.productName.toLowerCase()) ||
            description.toLowerCase().includes(this.productName.toLowerCase())
          ) {
            return product;
          }
          return;
        });
        // console.log(this.filteredCategories);
        return;
      }
      this.filteredCategories = this.categories;
    },
    //* Check before order
    async orderList() {
      this.backdropOrder = !this.backdropOrder;
      this.order = true;
    },
    hiding(hiden) {
      this.order = hiden;
    },
    //* Order data
    OrderData(order_code, qrcode, hours, minutes) {
      this.orderCode = order_code;
      this.qrCode = qrcode;
      this.hours = hours;
      this.minutes = minutes;
    },
    //* Sending WhatsApp
    sendWa(e) {
      e.preventDefault();
      const isValidNumber = /(^\d{11}$)|(^\d{12}$)|(^\d{13}$)/.test(this.wa);

      if (!isValidNumber) {
        this.showAlert();
        this.wa = "";
      } else {
        this.presentLoading();
        this.sending();
        localStorage.setItem("wa_number", this.wa);
      }
    },
    //* Sending WhatsApp api
    async sending() {
      this.disableSendWa = true;
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("Public-Key", `${process.env.VUE_APP_PUBLIC_KEY}`);

      let urlencoded = new URLSearchParams();
      urlencoded.append("order_code", this.orderCode);
      urlencoded.append("contact", this.wa);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      const res = await fetch(
        `${process.env.VUE_APP_BASE_URL}transaction/self_order/send`,
        requestOptions
      );
      const result = await res.json();
      if (result["message"] === "success") {
        this.successAlert();
        this.disableSendWa = false;
        this.product = [];
        this.order = false;
        this.orderCode = "";
        this.qrCode = "";
      }
    },
    //* Show Alert
    showAlert() {
      return this.$ionic.alertController
        .create({
          header: "Masukkan nomor WA",
          message: "Masukkan nomor WhatsApp anda dengan benar",
          buttons: ["OK"],
        })
        .then((a) => a.present());
    },
    //* Showign success alert
    successAlert() {
      return this.$ionic.alertController
        .create({
          header: "Sukses",
          message:
            "Kode pesanan telah kami kirimkan ke WhatsApp anda, \ngunakan sebelum batas maksimal yang ditentukan",
          buttons: ["OK"],
        })
        .then((a) => a.present());
    },
    //* Loading
    presentLoading() {
      return this.$ionic.loadingController
        .create({
          message: "Loading",
          duration: this.timeout,
        })
        .then((l) => {
          setTimeout(function() {
            l.dismiss();
          }, this.timeout);
          return l.present();
        });
    },
    detailsProduct(product) {
      this.showProductDetails = !this.showProductDetails;
      this.productDetails = product;
    },
    hideBackdrop() {
      this.showProductDetails = !this.showProductDetails;
      if (this.showLinkInput) {
        this.showLinkInput = !this.showLinkInput;
      }
    },
    hideBackdropOrder() {
      this.backdropOrder = !this.backdropOrder;
      this.order = false;
    },
    generateUrl(url) {
      this.url = url;
      this.showLinkInput = !this.showLinkInput;
    },
    checkProductAlready() {
      if (this.productDetails.id != undefined) {
        for (let i = 0; i < this.product.length; i++) {
          if (this.productDetails.id == this.product[i].product_id) {
            return true;
          }
        }
      }
      return false;
    },
    addProductFromDetails(product) {
      this.product.push(product);
      this.showProductDetails = !this.showProductDetails;
      if (this.showLinkInput) {
        this.showLinkInput = !this.showLinkInput;
      }
      localStorage.setItem("last-order", JSON.stringify(this.product));
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

#outlet {
  position: fixed;
  bottom: 0%;
  width: 99%;
  z-index: 2;
}
ion-button.trans {
  --color: blue;
  height: 60px;
  width: 60px !important;
  right: 16px;
  margin-bottom: 16px;
}

ion-button {
  --border-radius: 30px;
  --height: 3rem;
}
span {
  color: #8C1115;
}
.default-margin {
  margin-left: 21px;
  margin-right: 21px;
}
.float-submit-order {
  position: fixed;
  bottom: 0;
  width: 94%;
  background: #fff;
  transition: all 0.5s ease-in-out;
}
ion-toolbar {
  background: black;
}
.history-order {
  padding: 0.5rem;
  background: rgba(251, 138, 0, 0.7);
  display: flex;
  color: #fff;
  justify-content: space-between;
}
.history-button {
  font-size: 0.65rem;
  --background: #8C1115;
}
.history-order h5 {
  font-size: 0.8rem;
  margin: 0;
  /* color: #737373; */
}
.history-order p {
  font-size: 0.65rem;
  margin: 0;
  padding: 0;
  /* color: #222428; */
}
ion-button {
  --box-shadow: none;
}
ion-card {
  box-shadow: none;
}

ion-card.footers {
  width: 49% !important;
  float: right;
}

.qty-float {
  position: absolute;
  top: -5px;
  right: 8px;
  background: white;
  color: crimson;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* media query */
@media screen and (max-width: 600px) {
  ion-card.footers {
    width: auto !important;
    float: none;
  }
}

/* transition */
.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(50px);
  opacity: 0;
}
/* transition */
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
