<template>
  <transition name="slide-fade">
    <ion-card>
      <img :src="productDetails.image" />
      <ion-row class="title">
        <ion-col>
          <h5>{{ productDetails.name }}</h5>
          <p>{{ formatter.format(productDetails.price) }}</p>
          <p class="description">{{ productDetails.description }}</p>
        </ion-col>
        <ion-button @click="shareLoading" class="share">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            style="fill: rgba(239, 108, 0, 1);transform: ;msFilter:;"
          >
            <path
              d="M3 12c0 1.654 1.346 3 3 3 .794 0 1.512-.315 2.049-.82l5.991 3.424c-.018.13-.04.26-.04.396 0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3c-.794 0-1.512.315-2.049.82L8.96 12.397c.018-.131.04-.261.04-.397s-.022-.266-.04-.397l5.991-3.423c.537.505 1.255.82 2.049.82 1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3c0 .136.022.266.04.397L8.049 9.82A2.982 2.982 0 0 0 6 9c-1.654 0-3 1.346-3 3z"
            ></path>
          </svg>
        </ion-button>
      </ion-row>
      <ion-button
        v-if="
          productDetails.status == 'available' && checkProductAlready != true
        "
        class="btn-add"
        expand="block"
        @click="
          addToCart(
            productDetails.name,
            productDetails.price,
            productDetails.id,
            productDetails.detailId,
            productDetails.image,
            '',
            productDetails.tax
          )
        "
      >
        add to cart
      </ion-button>
      <ion-button
        v-if="productDetails.status != 'available'"
        class="btn-add"
        expand="block"
        color="danger"
      >
        out of stock
      </ion-button>
      <ion-button
        v-if="checkProductAlready"
        class="btn-add"
        expand="block"
        color="danger"
      >
        Already in cart
      </ion-button>
    </ion-card>
  </transition>
</template>

<script>
export default {
  name: "productDetails",
  props: ["productDetails", "checkProductAlready"],
  setup() {},
  data() {
    return {
      formatter: new Intl.NumberFormat("ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 2,
      }),
      timeout: 3000,
    };
  },
  methods: {
    hideModal(hide) {
      this.$emit("hide-modal", hide);
    },
    shareLoading() {
      return this.$ionic.loadingController
        .create({
          message: "Loading",
          duration: this.timeout,
        })
        .then((l) => {
          this.share();
          setTimeout(function() {
            l.dismiss();
          }, this.timeout);
          return l.present();
        });
    },
    async share() {
      // this.presentLoading();
      let myHeaders = new Headers();
      myHeaders.append("Public-Key", `${process.env.VUE_APP_PUBLIC_KEY}`);
      let id = parseInt(this.$route.params.id);

      let requestOptions = {
        methods: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const res = await fetch(
        `${process.env.VUE_APP_BASE_URL}product/url/${this.productDetails.id}?redirect=${process.env.VUE_APP_URL}${id}/${this.productDetails.id}`,
        requestOptions
      );
      if (res.status == 200) {
        const data = await res.json();
        this.$emit("generate-url", data.data.url);
      }
    },
    addToCart(name, price, productId, detailid, photo, note = "", tax) {
      let permanenttax = [];
      for (let i = 0; i < tax.length; i++) {
        if (tax[i]["tax_status"] == "permanent") {
          permanenttax.push(tax[i]);
        }
      }

      let product = {
        product_name: name,
        price: price,
        product_id: productId,
        product_detail_id: detailid,
        qty: 1,
        photo,
        note,
        tax: permanenttax,
      };
      this.$emit("add-product-from-details", product);
    },
  },
};
</script>

<style scoped>
ion-card {
  position: fixed;
  /* top: 0; */
  bottom: 50%;
  left: 0;
  width: 50%;
  transform: translate(50%, 50%);
  background: #fafafa;
  transition: all 0.5s ease-in-out;
  padding-bottom: 1rem;
  /* min-height: 80%; */
  margin: 0;
  z-index: 2;
}
img {
  margin: 0.8rem;
  border-radius: 5px;
  width: calc(100% - 1.6rem);
  height: 15.625rem;
  object-fit: cover;
}
.title {
  justify-content: space-between;
  margin: 0 0.8rem;
  align-items: flex-start;
}
.title h5 {
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0.2rem;
}
.title p {
  font-size: 0.8rem;
  margin-bottom: 0;
  margin-top: 0.2rem;
}
.title .description {
  font-size: 0.7rem;
}
ion-col {
  margin: 0;
}
.btn-add {
  box-shadow: none;
  margin: 0 0.8rem;
  --box-shadow: none;
  --background: #8C1115;
}
.share {
  --box-shadow: none;
  --background: none;
  color: #8C1115;
}

@media screen and (max-width: 600px) {
  ion-card {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    transform: none;
    width: 100%;
    background: #fafafa;
    transition: all 0.5s ease-in-out;
    padding-bottom: 1rem;
    /* min-height: 80%; */
    margin: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 2;
  }
}

/* transition */
.slide-fade-enter-active {
  transition: all 0.3s ease-in-out;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
</style>
