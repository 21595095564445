<template>
  <div class="ion-page">
    <ion-header>
      <ion-toolbar color="dark" mode="ios">
        <ion-title>Outlet List</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-infinite-scroll>
        <homePages v-bind:outlets="outlets" />
      </ion-infinite-scroll>
    </ion-content>
    <ion-footer>
      <ion-toolbar color="dark" mode="ios">
        <ion-title>Powered By <span>UNIQ</span></ion-title>
      </ion-toolbar>
    </ion-footer>
  </div>
</template>

<script>
import homePages from "@/components/homePage";
export default {
  name: "homePage",
  components: {
    homePages,
  },
  async created() {
    await this.getOutlet();
  },
  data() {
    return {
      outlets: [],
    };
  },
  methods: {
    //* Get outlets
    async getOutlet() {
      let myHeaders = new Headers();
      myHeaders.append("Public-Key", `${process.env.VUE_APP_PUBLIC_KEY}`);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const res = await fetch(
        `${process.env.VUE_APP_BASE_URL}outlet?latlng=-7.7845342,110.3817271`,
        requestOptions
      );
      const data = await res.json();
      this.outlets = data["data"];
    },
  },
};
</script>

<style scoped>
span {
  color: #f05e23;
}
ion-header {
  text-align: center;
}
ion-footer {
  text-align: center;
}
</style>
