<template>
  <transition name="bounce">
    <ion-card>
      <ion-col>
        <span>Link</span>
        <input type="text" :value="url" id="myInput" readonly />
        <ion-button @click="copyUrl" size="small">
          copy
        </ion-button>
      </ion-col>
    </ion-card>
  </transition>
</template>

<script>
export default {
  name: "inputUrlCopy",
  props: ["url"],
  setup() {},
  methods: {
    async openToast() {
      const toast = await this.$ionic.toastController.create({
        message: "Link url berhasil di copy",
        duration: 2000,
      });
      return toast.present();
    },
    copyUrl() {
      var copyText = document.getElementById("myInput");
      copyText.select();
      // copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.openToast();
    },
  },
};
</script>

<style scoped>
ion-card {
  position: fixed;
  top: 10%;
  right: 0;
  left: 0;
  transform: translate(50%, 50%);
  width: 50%;
  background: #fafafa;
  transition: all 0.5s ease-in-out;
  padding-bottom: 0.5rem;
  /* min-height: 80%; */
  margin: 0;
  border-radius: 0;
  z-index: 999999;
  /* margin: 0.8rem; */
  border-radius: 5px;
}
input {
  width: calc(100% - 1.6rem);
  height: 30px;
  margin: 0 0.8rem 0.2rem 0.8rem;
  border-radius: 5px;
  border: 2px solid #616161;
  box-sizing: border-box;
}
span {
  margin-left: 0.5rem;
  font-size: 0.9rem;
  font-weight: bold;
}
ion-button {
  float: right;
  margin-right: 0.8rem;
  margin-bottom: 0;
  --background: #8C1115;
  --box-shadow: none;
  font-size: 0.7rem;
}
/* transition */
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@media screen and (max-width: 600px) {
  ion-card {
    position: fixed;
    top: 10%;
    right: 0;
    left: 0;
    transform: none;
    width: calc(100% - 1.6rem);
    background: #fafafa;
    transition: all 0.5s ease-in-out;
    padding-bottom: 0.5rem;
    /* min-height: 80%; */
    margin: 0;
    border-radius: 0;
    z-index: 999999;
    margin: 0.8rem;
    border-radius: 5px;
  }
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
