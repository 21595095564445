var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide-fade"}},[_c('ion-card',[_c('img',{attrs:{"src":_vm.productDetails.image}}),_c('ion-row',{staticClass:"title"},[_c('ion-col',[_c('h5',[_vm._v(_vm._s(_vm.productDetails.name))]),_c('p',[_vm._v(_vm._s(_vm.formatter.format(_vm.productDetails.price)))]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(_vm.productDetails.description))])]),_c('ion-button',{staticClass:"share",on:{"click":_vm.shareLoading}},[_c('svg',{staticStyle:{"fill":"rgba(239, 108, 0, 1)","transform":""},attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M3 12c0 1.654 1.346 3 3 3 .794 0 1.512-.315 2.049-.82l5.991 3.424c-.018.13-.04.26-.04.396 0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3c-.794 0-1.512.315-2.049.82L8.96 12.397c.018-.131.04-.261.04-.397s-.022-.266-.04-.397l5.991-3.423c.537.505 1.255.82 2.049.82 1.654 0 3-1.346 3-3s-1.346-3-3-3-3 1.346-3 3c0 .136.022.266.04.397L8.049 9.82A2.982 2.982 0 0 0 6 9c-1.654 0-3 1.346-3 3z"}})])])],1),(
        _vm.productDetails.status == 'available' && _vm.checkProductAlready != true
      )?_c('ion-button',{staticClass:"btn-add",attrs:{"expand":"block"},on:{"click":function($event){return _vm.addToCart(
          _vm.productDetails.name,
          _vm.productDetails.price,
          _vm.productDetails.id,
          _vm.productDetails.detailId,
          _vm.productDetails.image,
          '',
          _vm.productDetails.tax
        )}}},[_vm._v(" add to cart ")]):_vm._e(),(_vm.productDetails.status != 'available')?_c('ion-button',{staticClass:"btn-add",attrs:{"expand":"block","color":"danger"}},[_vm._v(" out of stock ")]):_vm._e(),(_vm.checkProductAlready)?_c('ion-button',{staticClass:"btn-add",attrs:{"expand":"block","color":"danger"}},[_vm._v(" Already in cart ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }